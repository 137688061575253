<template>
  <div>
    <div id="sticky-navigation" :class="{ 'bg-transparent': isAtTop }"
      class="fixed w-full z-50 top-0 bg-primary md:flex hidden">
      <div class="ml-12">
        <a href="/"><img id="logo" :class="{ 'h-40': isAtTop }" class="h-20" src="@/assets/images/logo.png" /></a>
      </div>

      <div id="menu-items" :class="{ 'text-lg lg:text-xl xl:text-3xl': isAtTop }"
        class="absolute ml-auto top-1/2 right-0 -translate-y-1/2 mr-12 text-highlight flex space-x-4 font-italianno text-lg mt-1.5 ease-in-out duration-300">
        <a class="hover:text-white" href="/#services">Szolgáltatások</a>
        <a class="hover:text-white" href="/#portfolio">Portfólió</a>
        <a class="hover:text-white" href="/#about">Rólam</a>
        <a class="hover:text-white" href="#contact-section">Ajánlatkérés</a>
        <router-link class="hover:text-white" to="/galeria">Galéria</router-link>
      </div>
    </div>

    <nav :class="{ 'bg-transparent mt-10': isAtTop }"
      class="bg-primary md:hidden fixed top-0 w-full z-50 ease-in-out duration-300">
      <div :class="{ '!px-0 !pr-6': isAtTop }" class="flex items-center justify-between h-16 px-6">
        <router-link to="/"><img id="logo" :class="{ 'h-28': isAtTop }" class="h-16"
            src="@/assets/images/logo.png" /></router-link>
        <div class="flex items-center justify-between" @click="toggleMenu">
          <label for="menu-toggle"
            class="text-gray-300 hover:text-white focus:text-white focus:outline-none cursor-pointer">
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </label>
        </div>
      </div>
      <div ref="mobileMenu" class="text-center border-t border-b drop-shadow-lg" :class="[isMenuOpen ? '' : 'hidden']">
        <div class="px-2 pt-2 pb-3 space-y-1 bg-primary bg-opacity-70">
          <a @click="closeMenu" class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            href="/#about">Rólam</a>
          <a @click="closeMenu" class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            href="/#services">Szolgáltatások</a>
          <a @click="closeMenu" class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            href="/#portfolio">Portfólió</a>
          <a @click="closeMenu" class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            href="#footer">Kapcsolat</a>
          <a href="#contact-section"
            class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Ajánlatkérés</a>
          <router-link to="/galeria"
            class="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Galéria</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavigationLayout',
  data() {
    return {
      offset: 150,
      isMenuOpen: false,
      isAtTop: true
    }
  },
  methods: {
    checkScrollTop() {
      this.isAtTop = window.scrollY <= 0;
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu() {
      this.isMenuOpen = false
    }
  },
  mounted() {
    this.checkScrollTop();
    window.addEventListener('scroll', this.checkScrollTop, { passive: true });
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.checkScrollTop);
  }
}
</script>

<style>
#sticky-navigation {
  transition: background-color 0.5s ease;
}

#menu-items {
  transition: font-size 0.5s ease;
}

#logo {
  transition: height 0.5s ease;
}
</style>
