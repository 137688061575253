import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      isLoading: true
    };
  },
  mutations: {
    setLoading(state, loading) {
      state.isLoading = loading;
    }
  },
  actions: {
    startLoading({ commit }) {
      commit('setLoading', true);
    },
    stopLoading({ commit }) {
      setTimeout(() => {
        commit('setLoading', false);
      }, 500);
    }
  }
});

export default store;