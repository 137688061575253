import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/galeria',
    name: 'gallery',
    component: () => import('../views/GalleryListView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: async (to) => {
    const findEl = async (hash, retries = 0) => {
      return (
        document.querySelector(hash) ||
        new Promise((resolve) => {
          if (retries > 50) {
            return resolve(null);
          }
          setTimeout(() => {
            resolve(findEl(hash, retries + 1));
          }, 100);
        })
      );
    };

    const waitForCorrectOffset = async (el) => {
      return new Promise((resolve) => {
        const checkOffset = (retries = 0) => {
          const offset = el.offsetTop
          if (offset > 0 || retries > 50) {
            resolve(offset);
          } else {
            setTimeout(() => checkOffset(retries + 1), 100);
          }
        };
        checkOffset();
      });
    };

    if (to.hash) {
      const el = await findEl(to.hash);

      if (el) {
        const offset = await waitForCorrectOffset(el);
        return window.scrollTo({ top: offset, behavior: 'smooth' });
      } else {
        console.warn(`Element with hash ${to.hash} not found.`);
      }
    }

    return window.scrollTo({ top: 1000, behavior: 'smooth' });
  }
})

router.beforeEach((to, from, next) => {
  if (!to.hash)
    store.dispatch('startLoading');
    next();
});

export default router
