<template>
  <div class="bg-secondary py-10">
    <div class="max-w-screen-xl mx-auto">
      <h1 id="portfolio" class="text-center scroll-mt-32 text-highlight font-italianno text-5xl">{{ title }}</h1>
      <h3 class="text-center text-highlight font-italianno text-xl">{{ description }}</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-x-10 mx-10 pt-4">
        <router-link v-for="gallery in randomGalleries" :key="gallery.id" data-aos="fade-right" to="/galeria"
          class="mb-10 hover:text-white">
          <div class="hover:scale-105 transition-transform w-full h-96 bg-no-repeat bg-cover bg-top rounded-2xl"
            :style="{ backgroundImage: `url(${gallery.attributes.Images.data[0].attributes.url})` }"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GallerySection',
  props: {
    title: { type: String },
    description: { type: String },
  },
  data() {
    return {
      galleries: [],
      baseUrl: process.env.VUE_APP_STRAPI_URL
    }
  },
  computed: {
    randomGalleries() {
      return this.galleries
        .slice()
        .sort(() => 0.5 - Math.random())
        .slice(0, 3);
    }
  },
  async mounted() {
    await this.fetchGalleries();
  },
  methods: {
    async fetchGalleries() {
      try {
        const response = await this.axios.get(`/api/galleries?populate=Images`);

        this.galleries = response.data.data.map(gallery => {
          const images = gallery.attributes.Images.data.map(image => {
            const imageAttributes = image.attributes;
            const formats = imageAttributes.formats;

            for (let format in formats) {
              formats[format].url = `${this.baseUrl}${formats[format].url}`;
            }

            imageAttributes.url = `${this.baseUrl}${imageAttributes.url}`;

            return {
              ...image,
              attributes: {
                ...imageAttributes,
                formats,
              },
            };
          });

          return {
            ...gallery,
            attributes: {
              ...gallery.attributes,
              Images: {
                ...gallery.attributes.Images,
                data: images,
              },
            },
          };
        });

      } catch (error) {
        console.error('Error fetching galleries:', error);
      }
    }
  }
}
</script>
