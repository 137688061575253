<template>
  <div id="footer" class="h-96 bg-primary flex flex-col items-center justify-center">
    <img class="h-48 mx-auto" src="@/assets/images/logo.png">
    <h1 class="text-highlight font-italianno md:text-xl text-lg lg:text-2xl text-center">Ha egyéb rendelés vagy kérdésed van, keress e-mailben, vagy a megadott telefonszámon.</h1>
    <div class="text-center text-md text-highlight">
      <div class="mt-3">
        <font-awesome-icon icon="fa-solid fa-envelope" /> drobilichnikidekor@gmail.com<br>
        <font-awesome-icon icon="fa-solid fa-phone" /> +36/20-982-0264<br>
      </div>
      <div class="mt-3">
        <a class="hover:text-white" href=""><font-awesome-icon class="text-3xl mr-1" icon="fa-brands fa-instagram" /></a>
        <a class="hover:text-white" href=""><font-awesome-icon class="ml-1 text-3xl" icon="fa-brands fa-square-facebook" /></a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FooterLayout',
  }
</script>
