<template>
  <section id="about" class="bg-secondary lg:bg-primary py-10 md:py-16 lg:py-36 xl:py-36 xl:!pb-44">
    <div data-aos="fade-right" class="bg-secondary">
      <div class="relative left-1/2 -translate-x-1/2 max-w-screen-xl">
        <div class="mx-10 py-5">
          <h1 class="ml-5 md:ml-10 mb-4 text-3xl 2xl:text-5xl text-highlight font-italianno">{{ title }}</h1>
          <div class="ml-5 md:ml-10 md:w-1/2 text-lg 2xl:text-lg text-white" v-html="description"></div>
        </div>
        <img class="scroll-mt-36 h-72 md:h-full lg:h-96 2xl:h-flower -z-10 absolute top-1 md:-top-20 ml-5 md:ml-10 md:mt-10 opacity-80 md:opacity-50" src="@/assets/images/flower1-cropped.png" />
        <div
          class="max-w-xs mx-auto md:absolute md:right-0 md:mr-12 lg:mr-32 bg-no-repeat bg-cover bg-top md:top-1/2 lg:top-38 2xl:top-56 md:-translate-y-1/2 md:h-96 h-about lg:h-about 2xl:h-2xl-about md:w-64 lg:w-72 2xl:w-80 rounded-2xl"
          :style="{ backgroundImage: `url( ${imageUrl})` }"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'AboutSection',
    props: {
      title: { type: String },
      description: {type: String },
      imageUrl: { type: String}
    }
  }
</script>
