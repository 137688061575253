import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import 'aos/dist/aos.css'
import AOS from 'aos'
import axios from 'axios'
import VueAxios from 'vue-axios'

library.add(fas)
library.add(fab)

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_STRAPI_URL,
});

const fetchDynamicRoutes = async () => {
    const response = await axiosInstance.get('/api/galleries?populate=Hero.Images');
    return response.data.data;
};

const addDynamicRoutes = async () => {
    const dynamicData = await fetchDynamicRoutes();
    dynamicData.forEach(item => {
      const route = {
        path: `/galeria/${item.attributes.Slug}`,
        name: item.attributes.Slug,
        component: () => import(`./views/GalleryDetailsView.vue`),
        props: { data: item }
      };
      router.addRoute(route);
    });
  };
  
  
  addDynamicRoutes().then(() => {
    const app = createApp(App);
  
    app.component('font-awesome-icon', FontAwesomeIcon);
    app.use(store);
    app.use(router);
    app.use(AOS.init());
    app.use(VueAxios, axiosInstance);
  
    app.mount('#app');
  });
