<template>
  <div class="relative isolate bg-secondary px-6 lg:px-24 xl:px-8 py-24 sm:py-32" id="contact-section">
    <img
      class="scroll-mt-36 h-72 md:h-full lg:h-96 2xl:h-flower -z-10 absolute top-1 right-0 md:right-96 md:top-14 ml-5 md:ml-10 md:mt-24 opacity-80 md:opacity-50"
      src="@/assets/images/flower1-cropped.png" />
    <div class="mx-auto max-w-xl lg:max-w-7xl">
      <h1 class="text-3xl 2xl:text-5xl text-white">{{title}}</h1>
      <p class="mt-2 text-lg leading-8 text-white">{{shortDescription}}</p>
      <div class="mt-16 flex flex-col gap-40 sm:gap-y-20 lg:flex-row">
        <div class="lg:flex-auto max-w-xl">
          <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label for="first-name" class="block text-sm font-semibold leading-6 text-white required">Név</label>
              <div class="mt-2.5">
                <input type="text" name="first-name" id="first-name" v-model="form.name" @change="v$.name.$touch"
                  :class="{
                  '!border-red-600 !focus:border-red-600 border': v$.name.$error,
                  '!border-green-500 border': !v$.name.$invalid,
                }"
                  class="block w-full rounded-md px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                <span v-if="v$.name.$error" class="text-xs text-red-600">{{ v$.name.$silentErrors[0].$message }}</span>
              </div>
            </div>
            <div>
              <label for="email" class="block text-sm font-semibold leading-6 text-white required">E-mail</label>
              <div class="mt-2.5">
                <input type="text" name="email" id="email" v-model="form.email" @change="v$.email.$touch"
                  :class="{
                  '!border-red-600 !focus:border-red-600 border': v$.email.$error,
                  '!border-green-500 border': !v$.email.$invalid,
                }"
                  class="block w-full rounded-md border-0 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                  <span v-if="v$.email.$error" class="text-xs text-red-600">{{ v$.email.$silentErrors[0].$message }}</span>
              </div>
            </div>
            <div>
              <label for="wedding-date" class="block text-sm font-semibold leading-6 text-white required">Dátum</label>
              <div class="mt-2.5">
                <input id="wedding-date" name="wedding-date" type="date" v-model="form.weddingDate" @change="v$.weddingDate.$touch"
                  :class="{
                  '!border-red-600 !focus:border-red-600 border': v$.weddingDate.$error,
                  '!border-green-500 border': !v$.weddingDate.$invalid,
                }"
                  class="block w-full rounded-md border-0 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                  <span v-if="v$.weddingDate.$error" class="text-xs text-red-600">{{ v$.weddingDate.$silentErrors[0].$message }}</span>
              </div>
            </div>
            <div>
              <label for="wedding-location" class="block text-sm font-semibold leading-6 text-white required">Helyszín</label>
              <div class="mt-2.5">
                <input type="url" name="wedding-location" id="wedding-location" v-model="form.weddingLocation" @change="v$.weddingLocation.$touch"
                  :class="{
                  '!border-red-600 !focus:border-red-600 border': v$.weddingLocation.$error,
                  '!border-green-500 border': !v$.weddingLocation.$invalid,
                }"
                  class="block w-full rounded-md border-0 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6">
                  <span v-if="v$.weddingLocation.$error" class="text-xs text-red-600">{{ v$.weddingLocation.$silentErrors[0].$message }}</span>
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="message" class="block text-sm font-semibold leading-6 text-white required">Üzenet</label>
              <div class="mt-2.5">
                <textarea id="message" name="message" v-model="form.message" @change="v$.message.$touch"
                  :class="{
                  '!border-red-600 !focus:border-red-600 border': v$.message.$error,
                  '!border-green-500 border': !v$.message.$invalid,
                }"
                  placeholder="hány fő? milyen lesz a stílus? milyen dekor elemeket kértek? mennyi a maximális költség keret? stb.."
                  rows="4"
                  class="block w-full rounded-md border-0 px-3.5 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"></textarea>
                  <span v-if="v$.message.$error" class="text-xs text-red-600">{{ v$.message.$silentErrors[0].$message }}</span>
              </div>
            </div>
          </div>
          <div class="relative flex items-start mt-4">
            <div class="flex h-6 items-center">
              <input id="terms" aria-describedby="terms-description" name="terms" type="checkbox" v-model="form.termsAccepted" @input="v$.termsAccepted.$touch"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
            </div>
            <div class="ml-3">
              <label for="terms" class="font-medium text-white" 
            :class="{
              '!text-red-600': v$.termsAccepted.$error && v$.termsAccepted.$dirty,
            }"
            >Elfogadom az <a :href="`${termsDocsUrl}`" target="_blank"
                  class="underline">adatkezelési nyilatkoztatot</a></label>
            </div>
          </div>
          <div class="mt-4">
            <button type="submit" @click="submitForm"
              class="block w-full rounded-md bg-primary px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">{{ submitText }}</button>
          </div>
        </div>
        <div class="lg:mt-6 lg:w-96 lg:flex-none">
          <figure class="mt-10">
            <span class="text-lg font-semibold leading-8 text-white text-center">
              <p>{{longDescription}}</p>
            </span>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import emailjs from 'emailjs-com';
import { defineComponent, reactive, computed } from 'vue';

export default defineComponent({
  name: 'ContactSection',
  props: {
    title: { type: String },
    longDescription: {type: String },
    shortDescription: {type: String },
    submitText: { type: String},
    termsDocsUrl: { type: String }
  },
  setup() {
    const form = reactive({
      name: '',
      email: '',
      weddingDate: '',
      weddingLocation: '',
      message: '',
      termsAccepted: false,
    });

    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage('Név mező kitöltése kötelező!', required),
        },
        email: {
          required: helpers.withMessage('Email mező kitöltése kötelező!', required),
          email: helpers.withMessage('Rossz email formátum', email),
        },
        weddingDate: {
          required: helpers.withMessage('Dátum mező kitöltése kötelező!', required),
        },
        weddingLocation: {
          required: helpers.withMessage('Helyszín mező kitöltése kötelező!', required),
        },
        message: {
          required: helpers.withMessage('Üzenet mező kitöltése kötelező!', required),
        },
        termsAccepted: {
          checked: (value) => value === true
        },
      };
    });

    const v$ = useVuelidate(rules, form);

    const resetForm = () => {
      form.name = '';
      form.email = '';
      form.weddingDate = '';
      form.weddingLocation = '';
      form.message = '';
      form.termsAccepted = false;
      v$.value.$reset();
    };

    const submitForm = async (e) => {
      e.preventDefault();
      v$.value.$validate();
      if (!v$.value.$error) {
        try {
          const request = await sendEmail(form);
          if (request) {
            console.log('Message sent!');
            resetForm();
          }
        } catch (err) {
          console.log(err);
          console.log('Something went wrong!');
        }
      }
    };

    const sendEmail = (data) => {
      return emailjs.send(process.env.VUE_APP_SERVICE_ID, process.env.VUE_APP_TEMPLATE_ID, data, process.env.VUE_APP_USER_ID)
        .then((response) => {
          return response;
        }, (err) => {
          return err;
        });
    };

    return {
      form,
      rules,
      v$,
      submitForm,
    };
  }
});
</script>