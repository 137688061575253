<template>
  <div id="services" class="bg-primary relative pt-16">
    <div class="max-w-screen-xl text-white text-center flex mx-auto">
      <div data-aos="fade-down" class="pb-10 md:pb-20 w-full mx-20">
        <img class="w-60 md:w-flower absolute -top-5 lg:ml-40 z-10 left-1/2 -translate-x-1/2 opacity-80 md:opacity-50" src="@/assets/images/flower2-cropped-flipped2.png">
        <div class="h-28 md:h-40 flex justify-center items-center scroll-mt-32" id="services">
          <h1 class="text-highlight font-italianno text-4xl md:text-7xl">{{ title }}</h1>
        </div>
        <div class="text-xl 2xl:text-2xl" v-html="description"></div>
      </div>
    </div>
  </div>

  <div class="bg-primary">
    <div class="relative md:pb-20 max-w-screen-xl mx-auto pb-10">
      <div class="grid grid-cols-1 gap-y-10 md:grid-cols-3 gap-x-10 mx-10">

        <div data-aos="flip-up" class="bg-secondary py-10 flex flex-col items-center rounded-2xl h-full">
          <img class="h-72 md:hidden absolute start-1/2 left-60 -translate-x-1/2 opacity-75" src="@/assets/images/flower2-cropped.png">
          <h1 class="text-center font-italianno text-highlight text-3xl 2xl:text-5xl">{{ leftBox.title }}</h1>
          <div class="text-white text-lg mx-10 mt-10 text-center" v-html=leftBox.description></div>
        </div>

        <div>
          <img data-aos="flip-up" class="rounded-2xl h-full object-cover" :src=middleBox.image />
        </div>

        <div data-aos="flip-up" class="bg-secondary py-10 flex flex-col items-center mb-10 rounded-2xl h-full">
          <img class="h-60 md:hidden absolute right-20 opacity-75" src="@/assets/images/flower1-cropped.png">
          <h1 class="font-italianno text-highlight text-3xl 2xl:text-5xl">{{ rightBox.title }}</h1>
          <div class="text-white text-lg mx-10 mt-10 text-center" v-html=rightBox.description></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ServicesSection',
    props: {
      title: { type: String },
      description: {type: String },
      leftBox: { type: Object },
      middleBox: { type: Object },
      rightBox: { type: Object }
    }
  }
</script>
