<template>
  <HeroSection :title=heroSection.title :heroImages=heroSection.images />
  <ServicesSection :title=serviceSection.title :description=serviceSection.description :leftBox="serviceSection.leftBox" :middleBox="serviceSection.middleBox" :rightBox="serviceSection.rightBox"/>
  <GallerySection :title=portfolioSection.title :description=portfolioSection.description  :leftBox=portfolioSection.leftBox :middleBox=portfolioSection.middleBox :rightBox=portfolioSection.rightBox />
  <AboutSection :title=aboutSection.title :description=aboutSection.description :imageUrl=aboutSection.imageUrl />
  <ContactSection :title="contactSection.title" :longDescription="contactSection.longDescription" :shortDescription="contactSection.shortDescription" :submitText="contactSection.submitText" :termsDocsUrl="contactSection.termsDocsUrl" />
</template>

<script>
  import store from '@/store';
  import markdownit from "markdown-it";
  import HeroSection from '@/components/homepage/HeroSection.vue'
  import AboutSection from '@/components/homepage/AboutSection.vue'
  import ServicesSection from '@/components/homepage/ServicesSection.vue'
  import GallerySection from '@/components/homepage/GallerySection.vue'
  import ContactSection from '@/components/homepage/ContactSection.vue';

  export default {
    name: 'HomeView',
    components: { 
      HeroSection,
      AboutSection, 
      ServicesSection, 
      GallerySection ,
      ContactSection
    },
    data: () => ({
      heroSection: { images:[], title: '' },
      serviceSection: { title:'', description: '', leftBox: {}, middleBox: {}, rightBox: {}},
      aboutSection: { title: '', description: '', imageUrl: ''},
      portfolioSection: { title: '', description: ''},
      contactSection: { title: '', longDescription: '', shortDescription: '', submitText: '', termsDocsUrl: ''},
    }),
    created() {
      const baseUrl = process.env.VUE_APP_STRAPI_URL;

      this.axios.get('api/home-page?populate=Hero.Images&populate=Services.Left&populate=Services.Middle.Image&populate=Services.Right&populate=About.Image&populate=Portfolio.Left.Image&populate=Portfolio.Middle.Image&populate=Portfolio.Right.Image&populate=Contact&populate=Contact.Terms')
      .then(response => {
        this.heroSection.title = response.data.data.attributes.Hero.Title
        this.heroSection.images = response.data.data.attributes.Hero.Images.data.map((obj) => baseUrl+obj.attributes.url);
        this.serviceSection.title = response.data.data.attributes.Services.Title
        this.serviceSection.description = renderMarkdown(response.data.data.attributes.Services.Description)
        this.serviceSection.leftBox.title = response.data.data.attributes.Services.Left.Title
        this.serviceSection.leftBox.description = renderMarkdown(response.data.data.attributes.Services.Left.Text)
        this.serviceSection.middleBox.image = baseUrl+response.data.data.attributes.Services.Middle.Image.data.attributes.url
        this.serviceSection.rightBox.title = response.data.data.attributes.Services.Right.Title
        this.serviceSection.rightBox.description = renderMarkdown(response.data.data.attributes.Services.Right.Text)
        this.aboutSection.title = response.data.data.attributes.About.Title
        this.aboutSection.description = renderMarkdown(response.data.data.attributes.About.Description)
        this.aboutSection.imageUrl = baseUrl+response.data.data.attributes.About.Image.data.attributes.url
        this.portfolioSection.title = response.data.data.attributes.Portfolio.Title
        this.portfolioSection.description = response.data.data.attributes.Portfolio.Subtitle

        this.contactSection.title = response.data.data.attributes.Contact.Title
        this.contactSection.longDescription = response.data.data.attributes.Contact.longDescription
        this.contactSection.shortDescription = response.data.data.attributes.Contact.ShortDescription
        this.contactSection.submitText = response.data.data.attributes.Contact.SubmitButton
        this.contactSection.termsDocsUrl = `${baseUrl}${response.data.data.attributes.Contact.Terms.data.attributes.url}`

        store.dispatch('stopLoading');
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  function renderMarkdown(field){
    const markdown = markdownit({html: true})
    return markdown.render(field.replace(/(?:\r\n|\r|\n)/g, `<br/>`))
  }
</script>
