<template>
  <div class="w-full overflow-hidden">
    <div id="hero-image" class="h-screen relative">
      <div class="background-image" :style="backgroundImageStyle"></div>
      <div class="w-full h-full flex backdrop-brightness-75">
        <div class="absolute top-1/2 md:bottom-1/4 left-1/2 -translate-x-1/2">
          <h2 class="text-3xl md:text-4xl xl:text-5xl text-highlight text-center font-italianno">{{ title }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeroSection',
    props: {
      heroImages: {
        type: Array
      },
      title: {
        type: String
      },
    },
    computed: {
      backgroundImageStyle() {
          return {
            backgroundImage: `url(${this.imagePath})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            animation: 'slow-zoom 5s linear infinite',
            transition: 'background-image 1s linear',
            willChange: 'transform'
          };
      },
      imagePath() {
        return this.backgroundUrl ? this.backgroundUrl : this.heroImages[0]
      }
    },
    data() {
      return {
        backgroundUrl: '',
        actualImage: 0,
        intervalId: null
      }
    },
    mounted() {
      this.startInterval();
    
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
    methods: {
      changeImage() {
      this.actualImage++;
      if (this.actualImage === this.heroImages.length) {
        this.actualImage = 0;
      }
      this.backgroundUrl = this.heroImages[this.actualImage];
    },
    startInterval() {
      this.intervalId = setInterval(this.changeImage, 5000); // Start the interval
    },

    },
  }
</script>

<style>
/*   #hero-image {
    animation: slow-zoom 20s infinite;
    transition: background-image 1s linear;
    will-change: transform;
  }

  @keyframes slow-zoom {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.5);
    }
  } */
  #hero-image {
  position: relative;
  overflow: hidden; /* Ensure the child element doesn't overflow */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* Ensure the background image stays behind the content */
}

@keyframes slow-zoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}
</style>
