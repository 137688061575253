<template>
  <div v-show="isLoading">
    <Loader />
  </div>
  <div v-show="!isLoading">
    <Navigation />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Navigation from '@/components/layout/Navigation.vue'
import Loader from '@/components/Loader.vue'
import Footer from '@/components/layout/Footer.vue'
import AOS from 'aos'
export default {
  name: 'App',
  components: {
    Navigation,
    Footer,
    Loader,
  },
  computed: {
    ...mapState(['isLoading'])
  },
  mounted() {
    setTimeout(() => {
      AOS.refresh();
    }, 1000)
  },
}
</script>

<style>
body {
  @apply font-italianno;
}
</style>
